import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import helloKittyImg from './hello-kitty.png'; // Bild falls erforderlich
import helloKittyVideo from './hello_kitty.mp4'; // Dein MP4 Video
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css'; 
import { images } from './imageLoader'; 
import helloKitty from './lovely-hello-kitty-holding-strawberry.png'
import { getRandomMessageForToday } from './message';  // Importiere die Funktion aus message.js
import ReactCanvasConfetti from 'react-canvas-confetti'; // Feuerwerk-Bibliothek

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [score, setScore] = useState(0); // Initialer Score
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const validUsers = [
    { username: 'Philipp', password: 'PhilippPass13092023' },
    { username: 'Leona', password: 'LeonaPass13092023' }
  ];

  const videoRef = useRef(null);
  const confettiInstance = useRef(null);

  // Funktion zum Starten des Feuerwerks
  const fireConfetti = () => {
    confettiInstance.current &&
      confettiInstance.current({
        particleCount: 200,
        spread: 70,
        origin: { y: 0.6 },
      });
  };

  // Score vom Backend abrufen
  useEffect(() => {
    fetch('backend.php')  // Endpunkt des PHP-Backends
      .then(response => response.json())
      .then(data => setScore(data.score));
  }, []);

  // Nachricht zufällig auswählen, wenn sich der Score ändert
  useEffect(() => {
    setMessage(getRandomMessageForToday());  // Verwende eine Funktion aus deiner Datei message.js
  }, [score]);

  const handleLogin = () => {
    const user = validUsers.find(user => user.username === username && user.password === password);
    if (user) {
      setIsLoggedIn(true);
    } else {
      alert('Invalid username or password');
    }
  };

  const updateScore = (newScore) => {
    fetch('backend.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ newScore })
    })
    .then(response => response.json())
    .then(data => setScore(data.score))
    .catch(error => console.error('Error updating score:', error));
  };

  const modifyScore = (value) => {
    const newScore = Math.max(Math.min(score + value, 100), -100);
    updateScore(newScore);
    if (videoRef.current) {
      videoRef.current.play();
      fireConfetti();
    }
  };

  const getProgressVariant = () => {
    if (score > 0) return 'success';
    if (score < 0) return 'danger';
    return 'warning';
  };

  if (!isLoggedIn) {
    return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="card p-4" style={{ maxWidth: '400px' }}>
          <h2 className="text-center mb-4">Hallo mein Schatzibububär! 🥰</h2>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="form-control mb-3"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="btn btn-primary w-100" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container text-center mt-3">
      <h1 className="mb-3">Die Hello Kitty Punkte App</h1>
      <h4 className="mb-4">{message}</h4>
      <br />
     
      {/* Video-Tag zum Abspielen des Hello Kitty MP4 */}
      <video ref={videoRef} width="320" height="240" >
        <source src={helloKittyVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      {/* Feuerwerk Canvas */}
      <ReactCanvasConfetti refConfetti={confettiInstance} className="confetti-canvas" />

      <br></br>     
      <div className="btn-group mb-2" role="group">
        <button className="btn btn-danger" onClick={() => modifyScore(-1)}>-1 😿</button>
        <button className="btn btn-danger" onClick={() => modifyScore(-3)}>-3 😾</button>
        <button className="btn btn-danger" onClick={() => modifyScore(-5)}>-5 🥺</button>
      </div>
      <div className="btn-group mb-2" role="group">
        <button className="btn btn-success" onClick={() => modifyScore(1)}>+1 😻</button>
        <button className="btn btn-success" onClick={() => modifyScore(3)}>+3 😽</button>
        <button className="btn btn-success" onClick={() => modifyScore(5)}>+5 💖</button>
      </div>
      <div className="progress mt-3" style={{ height: '25px'}}>
        <div className={`progress-bar bg-${getProgressVariant()}`} role="progressbar" style={{ width: `${Math.abs(score)}%` }}>
          {score}
        </div>
      </div>
      <Link to="/about" className="btn btn-info mt-3">Über uns 💕</Link>
      <Link to="/random-picture" className="btn btn-primary mt-3" style={{ marginLeft: '10px' }}>Ein Tag - Ein Bild 📸</Link>

    </div>
  );
};

const About = () => (
  <div className="container text-center mt-5">
    <h2>Über uns</h2>
    <p>Diese Seite wurde entwickelt, damit mein Schatzibububär auch mal den Score ordentlich festhalten kann! 💞</p>
    <br />
    <div className='row' style={{ justifyContent: 'center' }}>
      
        <img src={helloKitty} alt="Hello Kitty" className="img-fluid" style={{ maxWidth: '25%' }} />

    
    </div>
    <Link to="/leona/build" className="btn btn-primary mt-3">Zurück zur Startseite</Link>
  </div>
);

// Bildlogik für die Random-Komponente
const imageList = [
  images // Bilderliste aus imageLoader.js
];

// Berechne das Bild für den Tag basierend auf dem Tag des Jahres
const getRandomImageForToday = () => {
  const date = new Date();
  // Tag des Jahres berechnen (1. Januar = Tag 1, 31. Dezember = Tag 365)
  const dayOfYear = Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
  // Verwende den Modulo-Operator, um einen Index für das Bild zu berechnen
  const index = dayOfYear % images.length;
  return images[index]; // Gibt ein Bild für den Tag zurück
};

// In deiner Random-Komponente
const Random = () => {
  // Verwende getRandomImageForToday, um das Bild des Tages zu bekommen
  const todayImage = getRandomImageForToday();
  // Verwende getRandomMessageForToday, um die Nachricht für den Tag zu erhalten
  const message = getRandomMessageForToday();

  return (
    <div className="random-container text-center mt-5">
      <h2 className="styled-title">Ein Tag - Ein Bild</h2>
      <div className="image-container">
        <img src={todayImage} alt="Random of the day" className="img-fluid mt-3 styled-image" />
      </div>
      <div className="text-container mt-4">
        <h4 className="styled-message">{message}</h4>
      </div>
  <Link to="/leona/build" className="btn btn-primary mt-3">Zurück zur Startseite</Link>
    </div>
  );
};

// App-Komponente
const App = () => (
  <Router>
    <Routes>
      <Route path="/leona/build" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/random-picture" element={<Random />} />
    </Routes>
  </Router>
);

export default App;
