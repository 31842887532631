// Liste der Subjekte
const subjects = ["Ich", "Du"];

// Positive Verben für "Ich"
const verbsForIch = ["liebe", "schätze", "bewundere", "freue mich über", "genieße", "feiere"];

// Positive Verben für "Du"
const verbsForDu = ["erhellst", "füllst"];

// Liste der Objekte für positive Komplimente, angepasst für "Ich" und "Du"
const objectsForIch = [
  "deine Art", "deine Liebe", "dein Lächeln", "dein Herz", "unsere Momente", 
  "unser Leben", "diese Augenblicke", "deine Stärke", "deine Geduld", 
  "deine Kreativität", "unsere gemeinsame Zeit", "deine Freundlichkeit", "dein Mitgefühl"
];

const objectsForDu = [
  "dein Lächeln", "dein Herz", "deine Liebe", "deine Art", "unsere Momente", 
  "unser Leben", "diese Augenblicke", "deine Stärke", "deine Kreativität", "unsere gemeinsame Zeit"
];

// Feste Sätze ohne Subjekt-Verb-Objekt-Struktur
const additionalPhrases = [
  "Du bist unglaublich", 
  "Du machst die Welt schöner", 
  "Du erfüllst mein Herz mit Freude", 
  "Mit dir ist jeder Tag ein Geschenk", 
  "Ich bin dankbar für dich", 
  "Unsere Zeit ist unbezahlbar",
  "Jeder Moment mit dir ist besonders", 
  "Du bist ein Geschenk", 
  "Unsere Liebe ist einzigartig", 
  "Ich schätze jeden Augenblick mit dir"
];

// Funktion zum Erstellen zufälliger Nachrichten
export const getRandomMessageForToday = () => {
  const date = new Date();
  const dayOfYear = Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);

  // Subjekt wählen
  const subject = subjects[dayOfYear % subjects.length];

  // Je nach Subjekt die passende Verbform und Objekte auswählen
  let verb;
  let object;
  if (subject === "Ich") {
    verb = verbsForIch[dayOfYear % verbsForIch.length];
    object = objectsForIch[dayOfYear % objectsForIch.length];
  } else if (subject === "Du") {
    verb = verbsForDu[dayOfYear % verbsForDu.length];
    object = objectsForDu[dayOfYear % objectsForDu.length];
  }

  const additionalPhrase = additionalPhrases[dayOfYear % additionalPhrases.length];

  // Zufällig entscheiden, ob eine Zusatzphrase oder eine Subjekt-Verb-Objekt-Kombination genutzt wird
  const useAdditionalPhrase = Math.random() > 0.5;

  if (useAdditionalPhrase) {
    // Satzstruktur: "Du bist unglaublich."
    return `${additionalPhrase}.`;
  } else {
    // Satzstruktur: "Ich liebe deine Art." oder "Du erhellst dein Herz."
    return `${subject} ${verb} ${object}.`;
  }
};
